import React, { useState, useEffect, useRef } from 'react';
import Message from './message';
import DOMPurify from 'dompurify';
import './chat.css'; 

const Chat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const socket = useRef(null);
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    const connectWebSocket = () => {
        //socket.current = new WebSocket('wss://127.0.0.1:8000/ws/asob-chat');
        socket.current = new WebSocket('wss://www.lassedesk.top/ws/asob-chat');
        
        socket.current.onmessage = event => {
            let newMessage;
            try {
                newMessage = JSON.parse(event.data);
            } catch (error) {
                newMessage = { text: event.data, sender: 'server' };
            }
            newMessage.text = DOMPurify.sanitize(newMessage.text, { USE_PROFILES: { html: true } }); 
            setLoading(false);
            setMessages(prev => {
                const updatedMessages = [...prev, newMessage];
                return updatedMessages;
            });
        };

        socket.current.onopen = () => {
            console.log("Connection established!");
            clearTimeout(socket.current.reconnectTimeout);
            socket.current.heartbeatInterval = setInterval(() => {
                if (socket.current.readyState === WebSocket.OPEN) {
                    socket.current.send(JSON.stringify({ type: 'heartbeat' }));
                }
            }, 30000);
        };

        socket.current.onclose = () => {
            console.log("Connection closed! Reconnecting...");
            clearInterval(socket.current.heartbeatInterval);
            socket.current.reconnectTimeout = setTimeout(connectWebSocket, 1000);
        };

        socket.current.onerror = error => {
            console.error("WebSocket error:", error);
            socket.current.close();
        };
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socket.current) {
                socket.current.close();
            }
            clearTimeout(socket.current.reconnectTimeout);
            clearInterval(socket.current.heartbeatInterval);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && shouldScrollToBottom()) {
            scrollToLastMessage();  // Use the updated scroll function
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isOpen && shouldScrollToBottom()) {
            scrollToLastMessage();  // Use the updated scroll function
        }
    }, [messages, isOpen]);

    const sendMessage = () => {
        if (input.trim()) {
            const sanitizedInput = DOMPurify.sanitize(input);
            setMessages(prev => {
                const updatedMessages = [...prev, { text: sanitizedInput, sender: 'user' }];
                if (isOpen && shouldScrollToBottom()) {
                    scrollToLastMessage();  // Use the updated scroll function
                }
                return updatedMessages;
            });
            socket.current.send(JSON.stringify({ text: sanitizedInput, sender: 'user' }));
            setInput('');
            setLoading(true);
        }
    };

    const handleInput = event => setInput(event.target.value);
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            sendMessage();
            event.preventDefault();
        }
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setTimeout(() => {
                if (isOpen) {
                    inputRef.current?.focus();
                }
            }, 300);
        }
    };

    const shouldScrollToBottom = () => {
        const chatMessagesElement = document.getElementById('chat-messages');
        return chatMessagesElement.scrollTop + chatMessagesElement.clientHeight < chatMessagesElement.scrollHeight;
    };

    const scrollToLastMessage = () => {
        const chatMessagesElement = document.getElementById('chat-messages');
        const lastMessageElement = messagesEndRef.current?.previousSibling; // Get the last message

        if (chatMessagesElement && lastMessageElement) {
            const lastMessageTop = lastMessageElement.offsetTop;
            const lastMessageHeight = lastMessageElement.scrollHeight;
            const chatHeight = chatMessagesElement.clientHeight;

            if (lastMessageHeight > chatHeight) {
                // If the last message is taller than the viewable area, scroll to the top of the last message
                chatMessagesElement.scroll({
                    top: lastMessageTop,
                    behavior: 'smooth',
                });
            } else {
                // Otherwise, scroll to the bottom of the chat
                chatMessagesElement.scroll({
                    top: chatMessagesElement.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    };
    // <img src="/Logo-ASOB.svg" alt="Logo" className="chat-logo"
    //<img src="https://lassedesk.top/asob-0924/Logo-ASOB.svg" alt="Logo" className="chat-logo" />
    return (
        <div className="chat-widget" id="chat-widget" onClick={(e) => e.stopPropagation()}>
            <div className="chat-icon" id="chat-icon" onClick={toggleChat}>
                &#x1F4AC;
            </div>
            <div ref={chatContainerRef} id="chat-container" className={`chat-container ${isOpen ? 'expanded' : ''}`}>
                <div className="chat-header" id="chat-header">
                    <img src="https://lassedesk.top/asob-0924/Logo-ASOB.svg" alt="Logo" className="chat-logo" />
                    <span>ASOB KI-Assistent</span>
                    <button className="close-chat" id="close-chat" onClick={toggleChat}>&#10005;</button>
                </div>
                <div id="chat-messages" className="messages">
                    {messages.map((msg, index) => (
                        <Message key={index} text={msg.text} sender={msg.sender} />
                    ))}
                    {loading && (
                        <div className="loading-indicator" id="loading-indicator">
                            <div className="dot-flashing"></div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="input-area" id="input-area">
                    <input
                        ref={inputRef}
                        className="form-control"
                        id="form-control"
                        value={input}
                        onChange={handleInput}
                        onKeyDown={handleKeyDown}
                        placeholder="Stellen Sie eine Frage..."
                    />
                    <button className="send-button" id="send-button" onClick={sendMessage}>&#9658;</button>
                </div>
            </div>
        </div>
    );
};

export default Chat;
