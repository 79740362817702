// src/Message.js
import React from 'react';
import './chat.css'; // Ensure the CSS is imported here

const Message = ({ text, sender }) => {
    return (
        <div className={`message ${sender}`}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default Message;